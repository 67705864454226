import React from "react";
import "./App.css";
import Main from "./containers/Main";
import { ThemeProvider } from "styled-components";
import { chosenTheme } from "./theme";
import { GlobalStyles } from "./global";
import ThemedLayout from "./theme";

function App() {
  return (
    <ThemeProvider theme={chosenTheme}>
      <GlobalStyles />
      <ThemedLayout>
        <div style={{ position: "relative", zIndex: 1 }}>
          <Main theme={chosenTheme} />
        </div>
      </ThemedLayout>
    </ThemeProvider>
  );
}

export default App;
