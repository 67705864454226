import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./FeelingProud.css";
import proudImage from "../../assests/images/portfolioDPGreen.png";
import anotherImage from "../../assests/images/SpideyDpPNG.png";

const FeelingProud = () => {
  const [currentImage, setCurrentImage] = useState(proudImage);

  const imageVariants = {
    initial: {
      scale: 0,
      opacity: 0,
      filter: "brightness(0) blur(10px)",
    },
    animate: {
      scale: 1,
      opacity: 0.7,
      filter: "brightness(1) blur(0px)",
      transition: {
        duration: 0.4,
        ease: "easeOut",
      },
    },
    exit: {
      scale: 0,
      opacity: 0,
      filter: "brightness(0) blur(10px)",
      transition: {
        duration: 0.4,
        ease: "easeIn",
      },
    },
  };

  const containerStyle = {
    width: "400px",
    height: "400px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  };

  const imageStyle = {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "50%",
  };

  return (
    <div style={containerStyle}>
      <AnimatePresence initial={false}>
        <motion.img
          key={currentImage}
          src={currentImage}
          alt="Feeling Proud"
          style={imageStyle}
          variants={imageVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          onMouseEnter={() => setCurrentImage(anotherImage)}
          onMouseLeave={() => setCurrentImage(proudImage)}
        />
      </AnimatePresence>
    </div>
  );
};

export default FeelingProud;
