import React from "react";
import "./Greeting.css";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import { greeting } from "../../portfolio";
import FeelingProud from "./FeelingProud";
import { motion } from "framer-motion";
import { Brain, Users, Rocket, Target } from "lucide-react";

function Greeting(props) {
  const theme = props.theme;

  const strengths = [
    {
      icon: <Brain size={20} />,
      title: "Strategic Problem Solver",
      description: "Converting complex challenges into elegant solutions",
    },
    {
      icon: <Users size={20} />,
      title: "Collaborative Leader",
      description: "Building and inspiring high-performing teams",
    },
    {
      icon: <Rocket size={20} />,
      title: "Innovation Driver",
      description: "Pushing boundaries in technology and design",
    },
    {
      icon: <Target size={20} />,
      title: "Results-Oriented",
      description: "Delivering high-quality solutions on time",
    },
  ];

  return (
    <div className="greet-main" id="greeting">
      <div className="greeting-main">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          {/* Your existing greeting content */}
          <div>
            <motion.h1
              className="greeting-text"
              style={{ color: theme.text }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: 0.6,
                delay: 0.2,
              }}
            >
              {greeting.title}
            </motion.h1>

            {greeting.nickname && (
              <motion.h2
                className="greeting-nickname"
                style={{ color: theme.text }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.6,
                  delay: 0.3,
                }}
              >
                {greeting.nickname}
              </motion.h2>
            )}

            <motion.p
              className="greeting-text-p subTitle"
              style={{ color: theme.secondaryText }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.6,
                delay: 0.4,
              }}
            >
              {greeting.subTitle}
            </motion.p>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.6,
                delay: 0.5,
              }}
            >
              <SocialMedia theme={theme} />
            </motion.div>

            <motion.div
              className="portfolio-repo-btn-div"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.6,
                delay: 0.9,
              }}
            >
              <Button
                text="Spidey Innovation Hub ⚡"
                newTab={true}
                href={greeting.portfolio_repository}
                theme={theme}
                className="portfolio-repo-btn"
              />
            </motion.div>
          </div>
        </motion.div>

        <motion.div
          className="greeting-image-div"
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          <FeelingProud theme={theme} />
        </motion.div>
      </div>
      <div className="strengths">
        <motion.div
          className="strength-tiles-container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.6,
            delay: 0.6,
          }}
        >
          <div className="strength-tiles">
            {strengths.map((strength, index) => (
              <motion.div
                key={index}
                className="strength-tile"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                  delay: 0.7 + index * 0.1,
                }}
                whileHover={{
                  scale: 1.02,
                  transition: {
                    duration: 0.3,
                    ease: "easeOut",
                  },
                }}
                onMouseMove={(e) => {
                  const rect = e.currentTarget.getBoundingClientRect();
                  const x = ((e.clientX - rect.left) / rect.width) * 100;
                  const y = ((e.clientY - rect.top) / rect.height) * 100;
                  e.currentTarget.style.setProperty("--mouse-x", `${x}%`);
                  e.currentTarget.style.setProperty("--mouse-y", `${y}%`);
                }}
                style={{
                  background: `linear-gradient(135deg, 
        ${theme.accentBright}11, 
        ${theme.accentBright}22
      )`,
                }}
              >
                <svg
                  className="border-svg"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="none"
                >
                  <defs>
                    <linearGradient
                      id={`border-gradient-${index}`}
                      gradientUnits="userSpaceOnUse"
                      x1="0"
                      y1="0"
                      x2="100%"
                      y2="0"
                    >
                      <stop offset="0%" stopColor={`${theme.accentBright}00`} />
                      <stop offset="50%" stopColor={theme.accentBright} />
                      <stop
                        offset="100%"
                        stopColor={`${theme.accentBright}00`}
                      />
                    </linearGradient>
                    <filter id={`glow-${index}`}>
                      <feGaussianBlur stdDeviation="1" result="blur" />
                      <feComposite
                        in="blur"
                        operator="over"
                        in2="SourceGraphic"
                      />
                    </filter>
                  </defs>
                  <motion.rect
                    className="border-line"
                    x="1"
                    y="1"
                    width="98"
                    height="98"
                    rx="12"
                    initial={{ pathLength: 0 }}
                    animate={{
                      pathLength: [0, 1],
                      pathOffset: [0, 1],
                    }}
                    transition={{
                      duration: 3,
                      ease: "linear",
                      repeat: Infinity,
                    }}
                    filter={`url(#glow-${index})`}
                  />
                </svg>

                <motion.div
                  className="strength-icon"
                  whileHover={{ scale: 1.1 }}
                  style={{
                    color: theme.accentBright,
                    background: `${theme.accentBright}22`,
                  }}
                >
                  {strength.icon}
                </motion.div>

                <motion.h3
                  className="strength-title"
                  style={{ color: theme.text }}
                >
                  {strength.title}
                </motion.h3>

                <motion.p
                  className="strength-description"
                  style={{
                    color: theme.secondaryText,
                  }}
                >
                  {strength.description}
                </motion.p>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default Greeting;
