import React from "react";
import styled, { keyframes } from "styled-components";
import { Cpu, Microchip, ServerIcon } from "lucide-react";

// Refined theme configuration
export const greenTheme = {
  body: "#1a2f1a",
  text: "#4eff4e",
  expTxtColor: "#ccffcc",
  highlight: "#00ff00",
  hoverText: "#ffffff",
  dark: "#001200",
  secondaryText: "#90ee90",
  imageHighlight: "#50c878",
  compImgHighlight: "#2e8b57",
  jacketColor: "#228b22",
  headerColor: "#32cd3277",
  splashBg: "#004000",
};

export const chosenTheme = greenTheme;

// Sophisticated animations
const subtlePulse = keyframes`
  0% { opacity: 0.15; transform: scale(1); filter: brightness(0.8); }
  50% { opacity: 0.25; transform: scale(1.05); filter: brightness(1.2); }
  100% { opacity: 0.15; transform: scale(1); filter: brightness(0.8); }
`;

const elegantFloat = keyframes`
  0% { transform: translate(0, 0) rotate(0deg); }
  25% { transform: translate(5px, -5px) rotate(1deg); }
  75% { transform: translate(-5px, 5px) rotate(-1deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
`;

const sophisticatedGlow = keyframes`
  0% { filter: drop-shadow(0 0 3px rgba(78, 255, 78, 0.3)); }
  50% { filter: drop-shadow(0 0 6px rgba(78, 255, 78, 0.5)); }
  100% { filter: drop-shadow(0 0 3px rgba(78, 255, 78, 0.3)); }
`;

const circuitFlow = keyframes`
  0% { stroke-dashoffset: 1000; opacity: 0.1; }
  50% { opacity: 0.3; }
  100% { stroke-dashoffset: 0; opacity: 0.1; }
`;

// Refined styled components
const BackgroundContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at center,
    rgba(26, 47, 26, 0.9) 0%,
    rgba(0, 18, 0, 0.95) 100%
  );
  overflow: hidden;
  z-index: 0;
`;

const GridOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
      rgba(78, 255, 78, 0.03) 1px,
      transparent 1px
    ),
    linear-gradient(90deg, rgba(78, 255, 78, 0.03) 1px, transparent 1px);
  background-size: 40px 40px;
  background-position: center center;
`;

const CircuitSVG = styled.svg`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const AnimatedCircuitElement = styled.div`
  position: absolute;
  color: #4eff4e;
  opacity: 0.2;
  animation: ${subtlePulse} ${(props) => 3 + props.randomDuration}s infinite
      ease-in-out,
    ${elegantFloat} ${(props) => 6 + props.randomDuration}s infinite ease-in-out,
    ${sophisticatedGlow} ${(props) => 4 + props.randomDuration}s infinite
      ease-in-out;
  animation-delay: ${(props) => props.delay}s;
  will-change: transform, opacity, filter;
`;

const CircuitPath = styled.path`
  stroke: #4eff4e;
  stroke-width: 1;
  fill: none;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: ${circuitFlow} 20s infinite linear;
  opacity: 0.1;
`;

const CircuitBackground = () => {
  // Generate circuit paths
  const generateCircuitPath = () => {
    const startX = Math.random() * 100;
    const startY = Math.random() * 100;
    return `M ${startX} ${startY} 
            Q ${startX + 50} ${startY - 50}, ${startX + 100} ${startY}
            T ${startX + 200} ${startY + 50}`;
  };

  return (
    <BackgroundContainer>
      <GridOverlay />

      <CircuitSVG>
        {Array.from({ length: 8 }).map((_, i) => (
          <CircuitPath
            key={`path-${i}`}
            d={generateCircuitPath()}
            style={{ animationDelay: `${i * 2}s` }}
          />
        ))}
      </CircuitSVG>

      {Array.from({ length: 12 }).map((_, i) => {
        const Icon = [Cpu, Microchip, ServerIcon][i % 3];
        const size = 16 + Math.random() * 16;
        const randomDuration = Math.random() * 2;

        return (
          <AnimatedCircuitElement
            key={`element-${i}`}
            style={{
              left: `${Math.random() * 90}%`,
              top: `${Math.random() * 90}%`,
            }}
            randomDuration={randomDuration}
            delay={Math.random() * 3}
          >
            <Icon
              size={size}
              style={{
                filter: "drop-shadow(0 0 2px rgba(78, 255, 78, 0.3))",
              }}
            />
          </AnimatedCircuitElement>
        );
      })}
    </BackgroundContainer>
  );
};

const ThemedLayout = ({ children }) => {
  return (
    <>
      <CircuitBackground />
      {children}
    </>
  );
};

export default ThemedLayout;

// theme.js
// export const blueTheme = {
//   body: "#EDF9FE",
//   text: "#001C55",
//   expTxtColor: "#000a12",
//   highlight: "#A6E1FA",
//   dark: "#00072D",
//   secondaryText: "#7F8DAA",
//   imageHighlight: "#0E6BA8",
//   compImgHighlight: "#E6E6E6",
//   jacketColor: "#0A2472",
//   headerColor: "#0E6BA877",
//   splashBg: "#001C55",
// };

// export const brownTheme = {
//   body: "#FFFEFD",
//   text: "#5D2A42",
//   expTxtColor: "#000a12",
//   highlight: "#FFF9EC",
//   dark: "#00072D",
//   secondaryText: "#8D697A",
//   imageHighlight: "#E29F95",
//   compImgHighlight: "#E6E6E6",
//   jacketColor: "#FB6376",
//   headerColor: "#E29F9577",
//   splashBg: "#5D2A42",
// };

// export const purpleTheme = {
//   body: "#F8EFF4",
//   text: "#231942",
//   expTxtColor: "#000a12",
//   highlight: "#E0B1CB",
//   dark: "#00072D",
//   secondaryText: "#655E7A",
//   imageHighlight: "#BE95C4",
//   compImgHighlight: "#E6E6E6",
//   jacketColor: "#5E548E",
//   headerColor: "#BE95C477",
//   splashBg: "#231942",
// };

// export const greenTheme = {
//   body: "#D0F0C0",
//   text: "#008200",
//   expTxtColor: "#000a12",
//   highlight: "#ADFF2F",
//   dark: "#00072D",
//   secondaryText: "#568203",
//   imageHighlight: "#55a630",
//   compImgHighlight: "#E6E6E6",
//   jacketColor: "#007f5f",
//   headerColor: "#55a63077",
//   splashBg: "#003F2F",
// };

// export const redTheme = {
//   body: "#FFF8E6",
//   text: "#6a040f",
//   expTxtColor: "#000a12",
//   highlight: "#ffba08",
//   dark: "#03071e",
//   secondaryText: "#964F56",
//   imageHighlight: "#dc2f02",
//   compImgHighlight: "#E6E6E6",
//   jacketColor: "#9d0208",
//   headerColor: "#dc2f0277",
//   splashBg: "#6a040f",
// };

// export const blackTheme = {
//   body: "#E5E5E5",
//   text: "#14213d",
//   expTxtColor: "#000a12",
//   highlight: "#ffffff",
//   dark: "#000000",
//   secondaryText: "#5A6377",
//   imageHighlight: "#fca311",
//   compImgHighlight: "#E6E6E6",
//   jacketColor: "#8d99ae",
//   headerColor: "#fca31177",
//   splashBg: "#14213d",
// };

// export const pinkTheme = {
//   body: "#FEE9F2",
//   text: "#620E34",
//   expTxtColor: "#000a12",
//   highlight: "#FBA7CD",
//   dark: "#31071A",
//   secondaryText: "#ef476f",
//   imageHighlight: "#ef476f",
//   compImgHighlight: "#E6E6E6",
//   jacketColor: "#8d99ae",
//   headerColor: "#ef476f77",
//   splashBg: "#620E34",
// };

// export const violetTheme = {
//   body: "#F4EEFC",
//   text: "#430A58",
//   expTxtColor: "#000a12",
//   highlight: "#D6BEF4",
//   dark: "#21052C",
//   secondaryText: "#875599",
//   imageHighlight: "#9b5de5",
//   compImgHighlight: "#E6E6E6",
//   jacketColor: "#763D8B",
//   headerColor: "#9b5de577",
//   splashBg: "#430A58",
// };

// export const tealTheme = {
//   body: "#E6FAF5",
//   text: "#084c61",
//   expTxtColor: "#000a12",
//   highlight: "#9BEED8",
//   dark: "#031E26",
//   secondaryText: "#528190",
//   imageHighlight: "#07beb8",
//   compImgHighlight: "#E6E6E6",
//   jacketColor: "#56a3a6",
//   headerColor: "#07beb877",
//   splashBg: "#084c61",
// };

// export const orangeTheme = {
//   body: "#FFF7F0", // Softer, lighter background for a cleaner look
//   text: "#FF2400", // Slightly brighter and more vibrant text color
//   expTxtColor: "#0D0D0D", // Darker text color for better readability
//   highlight: "#FBCEB1", // Lighter highlight for a subtle emphasis
//   dark: "#4A1E0A", // Rich, dark color for depth
//   secondaryText: "#D2691E", // Softer secondary text color
//   imageHighlight: "#FF4500", // Vivid highlight for images
//   compImgHighlight: "#F5F5F5", // Very light grey for a clean look
//   jacketColor: "#C41E3A", // Slightly more muted red for balance
//   headerColor: "#FF6347AA", // Transparent header for a modern feel
//   splashBg: "#CC3300", // Consistent with the primary text color for cohesion
// };

// export const yellowTheme = {
//   body: "#FFD95D",
//   text: "#5f4339",
//   expTxtColor: "#000a12",
//   highlight: "#ffff73",
//   dark: "#8d6e63",
//   secondaryText: "#5f4339",
//   imageHighlight: "#ffea3d",
//   compImgHighlight: "#E6E6E6",
//   jacketColor: "#4e342e",
//   headerColor: "#FFEA3D",
//   splashBg: "#5f4339",
// };

// export const materialDarkTheme = {
//   body: "#263238",
//   text: "#aeaeae",
//   expTxtColor: "#000a12",
//   highlight: "#4f5b62",
//   dark: "#000a12",
//   secondaryText: "#aeaeae",
//   imageHighlight: "#607d8b",
//   compImgHighlight: "#E6E6E6",
//   jacketColor: "#8eacbb",
//   headerColor: "#34515e",
//   splashBg: "#4f5b62",
// };

// export const materialLightTheme = {
//   body: "#ffffff",
//   text: "#4c2b91",
//   expTxtColor: "#000000",
//   highlight: "#E9E3F5",
//   dark: "#1d0c41",
//   secondaryText: "#7d56c2",
//   imageHighlight: "#2b1958",
//   compImgHighlight: "#E1E2E1",
//   jacketColor: "#E1E2E1",
//   headerColor: "#E1E2E1",
//   splashBg: "#7d56c2",
// };

// export const materialTealTheme = {
//   body: "#ffffff",
//   text: "#05505E",
//   expTxtColor: "#000000",
//   highlight: "#a1dded",
//   dark: "#07292c",
//   secondaryText: "#05505E",
//   imageHighlight: "#0a343c",
//   compImgHighlight: "#E1E2E1",
//   jacketColor: "#E1E2E1",
//   headerColor: "#E1E2E1",
//   splashBg: "#05505E",
// };

// export const chosenTheme = greenTheme;
